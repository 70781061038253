<!--
 * @Author: your name
 * @Date: 2021-01-20 14:35:18
 * @LastEditTime: 2021-04-02 18:34:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\sample\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation :msg="msg"></navigation>
    <!-- <embed :src="urlmsg" type="application/pdf" width="100%" height="100%" internalinstanceid="81" /> -->
    <!-- <iframe src="https://api.luobolieshou.com/upload/20210719/1fc32014a1f2271fdcae2053afa8fdf4.pdf" width="200" height="800">
      该浏览器无法支持PDF，请点击查看：
      <a href="PDF地址">下载 PDF</a></iframe
    > -->
    <img src="../../assets/allbaogao.jpg" alt="">
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
export default {
  components: {
    sharepage,
    share,
    
  },
  data() {
    return {
      msg: "报告样例",
      innh:"800",
      urlmsg:"https://api.luobolieshou.com/upload/20210719/1fc32014a1f2271fdcae2053afa8fdf4.pdf"
    };
  },
  created() {
    this.clickshare();
    this.innh = window.innerheight;
  },
  methods: {
    clickshare() {
      let obj = JSON.stringify({
        sharetitle: "企业人力风控卫士来啦",
        sharedesc: "3秒辨别履历真伪，精准客观；专业的人，用专业靠谱的萝卜背调",
        isshare: "1",
        share_phone:localStorage.getItem("phone")
      });
      localStorage.setItem("shareobj", obj);
      // window.location = "/sharepage";
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
iframe{
  border: none;
}
img {
  width: 100%;
  display: block;
}
.allmsgclass {
  background-color: #ffffff;
}
</style>
